import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/skills.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt="I'm Valentin!" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>I am also on YouTube</h1>
          <p>
            I love helping others. One of the ways I do that is by creating tutorials or by sharing my opinoion on
            different topics. I invite you to watch some of my tutorials.
          </p>
          <Button as={Link} href="https://www.youtube.com/channel/UCUUl_HXJjU--iYjUkIgEcTw">
            YouTube
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
