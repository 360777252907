import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card } from 'components/common';
import { Wrapper, Grid, Item, Content } from './styles';

export const Courses = () => {
  const { theme } = useContext(ThemeContext);
  const courses = [
    {
      id: 'CS10',
      title: `REST APIs with Postman for Absolute Beginners`,
      description: `Understand how APIs work. Use Postman to work with APIs.`,
      thumbnail: `https://img-b.udemycdn.com/course/750x422/2912472_af7b_3.jpg`,
      imgAlt: 'Intro to APIs course',
      clp: `https://www.udemy.com/course/api-with-postman-for-absolute-beginners/?couponCode=VDESPA_COM`,
    },
    {
      id: 'CS06',
      title: `Quick Introduction to Postman and API Testing`,
      description: `Learn the basics Postman in just 3 hours!`,
      thumbnail: `https://img-b.udemycdn.com/course/480x270/1780636_992b_3.jpg`,
      imgAlt: 'Postman course',
      clp: `https://www.udemy.com/course/postman-crash-course-for-beginners-learn-rest-api-testing/?couponCode=VDESPA_COM`,
    },
    {
      id: 'CS03',
      title: `Postman: The Complete Guide to REST API Testing`,
      description: `Postman API testing for manual and automated tests. Automate with Newman, Jenkins or any other CI tool.`,
      thumbnail: `https://img-c.udemycdn.com/course/480x270/1265410_cb96_2.jpg`,
      imgAlt: 'Postman course',
      clp: `https://www.udemy.com/course/postman-the-complete-guide/?couponCode=VDESPA_COM`,
    },
    {
      id: 'CS21',
      title: `Performance Testing: Introduction to k6 for Beginners`,
      description: `Use k6 to test the performance your web applications and APIs by running load tests, stress tests and spike tests.`,
      thumbnail: `https://img-c.udemycdn.com/course/480x270/5518640_b989.jpg`,
      imgAlt: 'k6 course',
      clp: `https://www.udemy.com/course/k6-load-testing-performance-testing/?couponCode=VDESPA_COM`,
    },
  ];

  const scrumCourses = [
    {
      id: 'CS07',
      title: `Scrum for beginners + Scrum Master Certification Preparation`,
      description: `Learn Scrum & prepare for your Scrum Master or Agile certification based on the Scrum Guide™ v2020.`,
      thumbnail: `https://img-c.udemycdn.com/course/480x270/1850902_6c52_6.jpg`,
      imgAlt: 'Scrum Master course',
      clp: `https://www.udemy.com/course/scrum-master-certification-preparation-mock-exam-questions-psm-i/?couponCode=VDESPA_COM`,
    },
    {
      id: 'CS09',
      title: `Scrum for Product Owners + Certification Preparation`,
      description: `Prepare for your Product Owner certification based on the Scrum Guide™ v2020.`,
      thumbnail: `https://img-b.udemycdn.com/course/480x270/2611510_3703_6.jpg`,
      imgAlt: 'Product Owner course',
      clp: `https://www.udemy.com/course/agile-scrum-product-owner-certification-preparation/?couponCode=VDESPA_COM`,
    },
  ];

  const devOpsCourses = [
    {
      id: 'CS22',
      title: `Jenkins: Jobs, Pipelines, CI/CD and DevOps for Beginners`,
      description: `Learn modern Jenkins by writing pipeline as code, using Docker and deploying to AWS.`,
      thumbnail: `https://img-c.udemycdn.com/course/480x270/5737068_3f88.jpg`,
      imgAlt: 'Jenkins CI/CD DevOps course',
      clp: `https://www.udemy.com/course/jenkins-ci-cd-pipelines-devops-for-beginners/?couponCode=VDESPA_COM`,
    },
    {
      id: 'CS16',
      title: `Quick Introduction to Git for GitLab`,
      description: `To work on any coding project, you need to know the basics around Git. `,
      thumbnail: `https://img-c.udemycdn.com/course/480x270/4076934_b2f9_2.jpg`,
      imgAlt: 'Quick Introduction to Git for GitLab',
      clp: `https://www.udemy.com/course/introduction-to-git-for-gitlab-projects/?couponCode=VDESPA_COM`,
    },
    {
      id: 'CS08',
      title: `Gitlab CI: Pipelines, CI/CD and DevOps for Beginners`,
      description: `Learn Gitlab CI / CD with Docker & DevOps: obtain valuable DevOps skills. Build pipelines.`,
      thumbnail: `https://img-c.udemycdn.com/course/480x270/2309602_ed2d_2.jpg`,
      imgAlt: 'Gitlab CI course',
      clp: `https://www.udemy.com/course/gitlab-ci-pipelines-ci-cd-and-devops-for-beginners/?couponCode=VDESPA_COM`,
    },
  ];

  const genAiCourses = [
    {
      id: 'CS20',
      title: `Introduction to OpenAI API & ChatGPT API for Developers`,
      description: `Integrate APIs from OpenAI such as ChatGPT, Whisper & DALL-E in your own applications.`,
      thumbnail: `https://img-c.udemycdn.com/course/750x422/5192882_641d_2.jpg`,
      imgAlt: 'Intro to OpenAI API & ChatGPT API course',
      clp: `https://www.udemy.com/course/introduction-to-openai-api-chatgpt-api-chatgpt-plugins/?couponCode=VDESPA_COM`,
    },
    {
      id: 'CS23',
      title: `Custom GPTs in ChatGPT`,
      description: `Build a custom GPT and chat with your own data. Add documents, generate images, and integrate with APIs and Zapier.`,
      thumbnail: `https://img-c.udemycdn.com/course/750x422/5672796_e0f9.jpg`,
      imgAlt: 'Build an API',
      clp: `https://www.udemy.com/course/openai-custom-gpt-chatgpt/?couponCode=VDESPA_COM`,
    },
  ];

  const otherCourses = [
    {
      id: 'SS02',
      title: `Learn how to build your first API`,
      description: `Learn how to build a simple API. You can take this course on Skillshare for FREE (14 days trial).`,
      thumbnail: `https://static.skillshare.com/cdn-cgi/image/width=448,quality=85,format=auto/uploads/video/thumbnails/e6fe13774aaec3736c1c5b39a4b9b8b9/original`,
      imgAlt: 'Build an API',
      clp: `https://www.skillshare.com/r/profile/Valentin-Despa/1704935`,
    },
  ];

  return (
    <Wrapper as={Container} id="courses">
      <h1>Courses</h1>
      <p>
        ✅ Looking for discounts? I am regularly sending DISCOUNT COUPONS by email.
        <b>
          <a href="https://sendfox.com/lp/m5vvvq"> Click here to get notified. 📩</a>
        </b>
      </p>
      <h2>GenAI courses</h2>
      <Grid>
        {genAiCourses.map((node) => (
          <Item key={node.id} as="a" href={node.clp} target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <Content>
                <h4>{node.title}</h4>
                <img alt={`${node.imgAlt}`} src={`${node.thumbnail}`} />
                <p>{node.description}</p>
              </Content>
            </Card>
          </Item>
        ))}
      </Grid>
      <br />
      <h2>Test automation courses</h2>
      <Grid>
        {courses.map((node) => (
          <Item key={node.id} as="a" href={node.clp} target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <Content>
                <h4>{node.title}</h4>
                <img alt={`${node.imgAlt}`} src={`${node.thumbnail}`} />
                <p>{node.description}</p>
              </Content>
            </Card>
          </Item>
        ))}
      </Grid>
      <br />
      <h2>DevOps courses</h2>
      <Grid>
        {devOpsCourses.map((node) => (
          <Item key={node.id} as="a" href={node.clp} target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <Content>
                <h4>{node.title}</h4>
                <img alt={`${node.imgAlt}`} src={`${node.thumbnail}`} />
                <p>{node.description}</p>
              </Content>
            </Card>
          </Item>
        ))}
      </Grid>
      <br />
      <h2>Agile & Scrum courses</h2>
      <Grid>
        {scrumCourses.map((node) => (
          <Item key={node.id} as="a" href={node.clp} target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <Content>
                <h4>{node.title}</h4>
                <img alt={`${node.imgAlt}`} src={`${node.thumbnail}`} />
                <p>{node.description}</p>
              </Content>
            </Card>
          </Item>
        ))}
      </Grid>
      <br />
      <h2>All other courses</h2>
      <Grid>
        {otherCourses.map((node) => (
          <Item key={node.id} as="a" href={node.clp} target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <Content>
                <h4>{node.title}</h4>
                <img alt={`${node.imgAlt}`} src={`${node.thumbnail}`} />
                <p>{node.description}</p>
              </Content>
            </Card>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  );
};
