import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/dev.png';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';

export const Intro = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          <h1>Hi, I am Valentin!</h1>
          <h4>Agile software developer & online instructor</h4>
          <Button as={Link} href="/courses">
            See what I teach
          </Button>
        </Details>
        <Thumbnail>
          <img src={dev} alt="I’m Valentin" />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
