import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card } from 'components/common';
import { Wrapper, Grid, Item, Content } from './styles';

export const Talks = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper as={Container} id="courses">
      <h2>Talks</h2>
      <Grid>
        <Item
          key="5"
          as="a"
          href="https://www.youtube.com/watch?v=8iWcTKN7-98"
          target="_blank"
          rel="noopener noreferrer"
          theme={theme}
        >
          <Card theme={theme}>
            <Content>
              <h4>Generative AI Meets Postman API Testing</h4>
              <h5>September 2023, TestFlix 2023 - live stream</h5>
              <iframe
                title="8iWcTKN7-98"
                width="381"
                height="214"
                src="https://www.youtube-nocookie.com/embed/8iWcTKN7-98"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <p>
                In an age where efficiency and automation are paramount, the integration of generative AI in Postman
                heralds a transformative approach to API testing.
              </p>
            </Content>
          </Card>
        </Item>
        <Item
          key="4"
          as="a"
          href="https://www.youtube.com/watch?v=a7G8Yrb5qWU"
          target="_blank"
          rel="noopener noreferrer"
          theme={theme}
        >
          <Card theme={theme}>
            <Content>
              <h4>Make Sure Your Tests Fail</h4>
              <h5>February 2021, Postman Galaxy 2021 - live stream</h5>
              <iframe
                title="a7G8Yrb5qWU"
                width="381"
                height="214"
                src="https://www.youtube-nocookie.com/embed/a7G8Yrb5qWU"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <p>Walk through badly written tests and see why they don't work as expected.</p>
            </Content>
          </Card>
        </Item>
        <Item
          key="3"
          as="a"
          href="https://www.crowdcast.io/e/south-west-test-27"
          target="_blank"
          rel="noopener noreferrer"
          theme={theme}
        >
          <Card theme={theme}>
            <Content>
              <h4>Introduction to Postman</h4>
              <h5>October 2020, South West Test (Bristol, UK) - live stream on Crowdcast</h5>
              <iframe
                title="Introduction to Postman"
                width="381"
                height="214"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                allowTransparency="true"
                src="https://www.crowdcast.io/e/south-west-test-27?navlinks=false&embed=true"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allow="microphone; camera;"
              />
              <p>
                In this session, we will take a quick dive into API testing with Postman and try to understand if
                Postman is the right tool for you.
              </p>
            </Content>
          </Card>
        </Item>
        <Item
          key="2"
          as="a"
          href="https://www.youtube.com/watch?v=bPFNTFjrc7M"
          target="_blank"
          rel="noopener noreferrer"
          theme={theme}
        >
          <Card theme={theme}>
            <Content>
              <h4>Test your Postman Trivia</h4>
              <h5>July 2020, live stream on Twitch</h5>
              <iframe
                title="bPFNTFjrc7M"
                width="381"
                height="214"
                src="https://www.youtube-nocookie.com/embed/bPFNTFjrc7M"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <p>
                Hanging out with Joyce Lin (@PetuniaGray) and giving out some swag to Postman users who know how to
                answer my questions.
              </p>
            </Content>
          </Card>
        </Item>
        <Item
          key="1"
          as="a"
          href="https://www.youtube.com/watch?v=zZGsze2a1wc"
          target="_blank"
          rel="noopener noreferrer"
          theme={theme}
        >
          <Card theme={theme}>
            <Content>
              <h4>10 Things You Didn't Know Postman Can Do</h4>
              <h5>Sept. 2019, POST/CON, San Francisco</h5>
              <iframe
                title="zZGsze2a1wc"
                width="381"
                height="214"
                src="https://www.youtube-nocookie.com/embed/zZGsze2a1wc"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <p>Short 15-minute talk given at POST/CON, the Postman User Conference in San Francisco.</p>
            </Content>
          </Card>
        </Item>
      </Grid>
    </Wrapper>
  );
};
